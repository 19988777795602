function initSelect2() {
    $(document).ready(function() {
        $('.js-example-basic-multiple').select2({
            width : '100%'
        });
        $('.js-example-basic-single').select2({
            width : '100%'
        });
    });

    /* $('.js-example-basic-single').change(function()  {
        $('#select2-val').val($(this).val()) ; 
    }) ; */ 
}
